import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "login",
    meta: {
      appFrame: false,
      navBar: false,
      basePath: true,
      allowedRoles: ['client'],
    },
    component: () =>
      import("../views/LoginUser.vue"),
  },
  {
    path: "/home",
    name: "home",
    meta: {
      appFrame: false,
      navBar: true,
      basePath: true,
      allowedRoles: ['client'],
    },
    component: () =>
      import("../views/Home.vue"),
  },
  {
    path: "/transactions",
    name: "transactions",
    meta: {
      appFrame: false,
      navBar: true,
      basePath: true,
      allowedRoles: ['client'],
    },
    component: () =>
      import("../views/transactions-client.vue"),
  },
  {
    path: "/subscriptions",
    name: "subscription",
    meta: {
      appFrame: false,
      navBar: true,
      basePath: true,
      allowedRoles: ['client'],
    },
    component: () =>
      import("../views/subscription.vue"),
  },
  {
    path: "/Cards",
    name: "card",
    meta: {
      appFrame: false,
      navBar: true,
      basePath: true,
      allowedRoles: ['client'],

    },
    component: () =>
      import("../views/Cards.vue"),
  },
  {
    path: "/payment/checkout/:id",
    name: "payment links 2",
    meta: {
      appFrame: false,
      navBar: false,
      basePath: false,
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/payment-links-2.vue"),
  },
  {
    path: "/payment/checkout/form/:id",
    name: "payment links",
    meta: {
      appFrame: false,
      navBar: false,
      basePath: false,
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/payment-inPage.vue"),
  },
  {
    path: "/payment/subscription/:id",
    name: "paymentSubscription",
    meta: {
      appFrame: false,
      navBar: false,
      basePath: false,
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/payment-subscription.vue"),
  },
  {
    path: "/process/transaction/:id",
    name: "ProcessTransaction",
    meta: {
      appFrame: false,
      navBar: false,
      basePath: false,
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/payment-qr.vue"),
  },
  {
    path: "/not-found",
    name: "not found",
    meta: {
      appFrame: false,
      navBar: false,
      basePath: false,
    },
    component: () => import(/* webpackChunkName: "about" */ "../views/404.vue"),
  },
  {
    path: "*",
    name: "not-found",
    meta: {
      appFrame: true,
      navBar: true,
    },
    component: () => import("../views/notFound.vue"),
  },
  {
    path: "/verifyTransaction/:id/:origin/",
    props: true,
    meta: {
      appFrame: false,
      navBar: false,
      basePath: false,
    },
    name: "successfulPayment",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/successfulPayment.vue"),
  },

  {
    path: "/verifyTransaction/:id/",
    props: true,
    meta: {
      appFrame: false,
      navBar: false,
      basePath: false,
    },
    name: "successfulPayment-1",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/successfulPayment.vue"),
  },

  {
    path: "/result/form-payment/:id/",
    props: true,
    meta: {
      appFrame: false,
      navBar: false,
      basePath: false,
    },
    name: "successfulPayment-2",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/successfulPayment.vue"),
  },
];

const router = new VueRouter({
  base: "/",
  mode: "history",
  routes,
});

export default router;
