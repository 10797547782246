<template>
  <div class="header-loby">
    

    <!-- <v-btn  fab small>
      <v-icon small> fa-solid fa-chevron-left </v-icon>
    </v-btn>
    <div class="text-welcome">
      <span style="font-size: 0.8em !important">Hola</span> <br />
      <span style="color: #ff5900"> {{ user.name }}</span>
    </div>
   
    <v-avatar>
      <v-img :src="user.picture"> </v-img>
    </v-avatar> -->
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import { db, fb } from "@/firebase";
export default {
  name: "header-loby",
  computed: {
    ...mapState(["user"]),
  },
  methods: {
    // logOut() {
    //   if (this.user) {
    //     fb.auth()
    //       .signOut()
    //       .then(() => {
    //         this._user(null);
    //         this._codeArea(null);
    //         this._timerSession(null);
    //         this.setPhone(null);
    //         this._selectEvent(null);
    //         if (this.$route.path != "/") {
    //           this.$router.push("/");
    //         }
    //       });
    //   }
    // },
  },
};
</script>
<style lang="scss" scoped>
.header-loby {
  width: 100%;
  
  display: flex;
  justify-content: space-between;
  .text-welcome {
    font-weight: 800;
    font-size: 1.5em;
    line-height: 1em;
  }
}
</style>