import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import "firebase/compat/functions";

let env = process.env.NODE_ENV;

var devConfig = {
  apiKey: "AIzaSyBE4DJkIv5Q9GLMr_N_GNTb_CmwHtune1A",
  authDomain: "el-ocho-dev.firebaseapp.com",
  projectId: "el-ocho-dev",
  storageBucket: "el-ocho-dev.appspot.com",
  messagingSenderId: "86761917155",
  appId: "1:86761917155:web:a26af1cac44d4006b9d3d6",
  measurementId: "G-D478PKYNBH",
};

var prodConfig = {
  apiKey: "AIzaSyDwqeEX-PH5IV9pMboDUSgz0g6nWAoTTho",
  authDomain: "el-ocho.firebaseapp.com",
  databaseURL: "https://el-ocho.firebaseio.com",
  projectId: "el-ocho",
  storageBucket: "el-ocho.appspot.com",
  messagingSenderId: "770366666625",
  appId: "1:770366666625:web:f6d79634f54385a09b1a80",
  measurementId: "G-2PBQGFMTGH",
};

firebase.initializeApp(env == "production" ? prodConfig : devConfig);

// if (env == "development") {
// firebase.functions().useFunctionsEmulator("http://localhost:5001");
// }

firebase.auth().languageCode = "es";

export const db = firebase.firestore();
export const fb = firebase;
