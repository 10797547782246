<template>
  <v-card max-width="600px" elevation="0" style="border-radius: 15px">
    <v-card-title>
      Ingresa tu # de celular
      <!-- <v-btn icon absolute right color="primary" @click="closedDialog">
        <v-icon>fa fa-close</v-icon>
      </v-btn> -->
    </v-card-title>

    <v-divider> </v-divider>
    <!-- <v-form style="width: 100%" ref="form" v-model="valid" lazy-validation> -->
    <v-card-text class="text-center flex-center pt-5 px-4">
      <inputPhone @retunValue="valueInput" @actions="onNext" />
    </v-card-text>
    <v-card-actions>
      <v-btn
        style="border-radius: 10px"
        class="primary mb-2"
        width="50%"
        height="50px"
        :loading="loading"
        @click="onNext"
        type="button"
        block
      >
        continuar
      </v-btn>
      <!-- <v-btn color="primary" text width="50%" height="40px"> Regístrate </v-btn> -->
    </v-card-actions>
    <!-- </v-form> -->
    <v-dialog persistent v-if="otpDialog" v-model="otpDialog" max-width="400">
      <otp
        :auth="authObject"
        ref="code"
        @cancel="(otpDialog = false), (loading = false)"
        @success="otpConfirmed"
      />
    </v-dialog>
  </v-card>
</template>
<script>
import { db, fb } from "@/firebase";
import inputPhone from "./inputPhone";
import otp from "./settings/insertCode.vue";
import { mapActions, mapState } from "vuex";
import countries from "../Countries/countries.json";
import country from "../Countries/country.json";
export default {
  components: { inputPhone, otp },
  name: "preLogin",

  data() {
    return {
      Countries: countries,
      Country: country,
      valid: true,
      dialog: false,
      otpDialog: false,
      authObject: null,
      verificationId: null,
      phone: null,
      code: null,
      loading: false,
      email: null,
      user: null,
      rule: [(v) => !!v || "Correo es requerido"],
      emailRules: [
        (v) => !!v || "Correo es requerido",
        (v) => /.+@.+\..+/.test(v) || "El correo no es valido",
      ],
    };
  },
  methods: {
    ...mapActions(["Alert_", "setPhone", "_codeArea", "setUser"]),
    valueInput(e, code) {
      // //console.debug(e, code);
      this.phone = e;
      this.code = code;
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    closedDialog() {
      this.$emit("closed");
      this.setUser(null);
    },
    onNext() {
      this.loading = true;

      if (this.phone != null) {
        this.mfaLogin();
      } else {
        this.loading = false;
        this.Alert_({
          text: "😬 Ingrese el campo requerido",
          timeout: 2000,
          btn_closed: true,
          icon: false,
          iconType: "mdi-check",
          type: "error",
        });
      }
    },
    mfaLogin() {
      var phone = `${this.code.callingCodes[0]}${this.phone}`;
      var datos = {
        k: phone,
      };
   
      var callableGenerateSmsTokenCall = fb
        .functions()
        .httpsCallable("callableGenerateSmsTokenCall");

      callableGenerateSmsTokenCall(datos).then((response) => {
        // //console.debug(response);
        this.otpDialog = true;
      });
    },
    otpConfirmed(otp) {
      this.otpDialog = false;
      var phone = `${this.code.callingCodes[0]}${this.phone}`;
      var datos = {
        k: phone,
        t: otp,
      };

      var callableValidateToken = fb
        .functions()
        .httpsCallable("callableValidateToken");

      callableValidateToken(datos)
        .then((response) => {
          // //console.debug(response.data.token);
          this.setPhone(this.phone);
          this._codeArea(this.code);
          this.singInToken(response.data.token);
        })
        .catch((error) => {
          this.Alert_({
            text: error.message,
            timeout: 2000,
            btn_closed: true,
            icon: false,
            iconType: "mdi-check",
            type: "error",
          });
          this.otpDialog = true;
        });
    },
    singInToken(token) {
      fb.auth()
        .signInWithCustomToken(token)
        .then(async (userCredential) => {
          //console.debug("userCredential", userCredential);
          this.loading = false;
          this.$emit("closed");
          this.$emit("NextOrder");
          // ...
        })
        .catch((error) => {
          // //console.debug(error);
        });
    },
  },
  async mounted() {},
};
</script>
<style lang="scss" scoped>
// html[data-scroll="0"] {

// }
.wrap-flag {
  position: relative;
  .flag {
    height: 56px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 45px;
      height: 35px;
      border-radius: 8px;
    }
    .select-flag {
      height: 250px;
      width: 100px;
      left: 0;
      top: 0;
      overflow-y: scroll;
      position: absolute;
      background-color: #f2f2f2;
      z-index: 100;
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      box-shadow: rgba(0, 0, 0, 0.435) 2px 2px 20px;
    }
  }
}
.list {
  background-color: #f2f2f2;
  overflow: scroll;
  border-radius: 20px;
}

// @include responsive(desktop) {
// 	font-size: 20px;
// 	//... all what you want
// }
ul {
  padding-left: 5px !important;
  li {
    list-style: none;
  }
}
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.wrap-flag {
  position: relative;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 10px;
  .flag {
    height: 36px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 40px;
      height: 30px;
      border-radius: 8px;
    }
    .select-flag {
      height: 250px;
      width: 100px;
      left: 0;
      top: 0;
      overflow-y: scroll;
      position: absolute;
      background-color: #f2f2f2;
      z-index: 100;
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      box-shadow: rgba(0, 0, 0, 0.435) 2px 2px 20px;
    }
  }
}
.list {
  background-color: #f2f2f2;
  overflow: scroll;
  border-radius: 20px;
}

// @include responsive(desktop) {
// 	font-size: 20px;
// 	//... all what you want
// }
ul {
  padding-left: 5px !important;
  li {
    list-style: none;
  }
}
.list {
  background-color: #f2f2f2;
  overflow: scroll;
  border-radius: 20px;
}
.img-text {
  display: flex;
  align-items: center;
  justify-content: space-between;
  img {
    width: 30px;
    height: 23px;
    border-radius: 5px;
  }
}
.form-conat {
  width: 100%;
}
.flex-start {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
</style>