<template>
  <v-app class="app-vue" id="inspire">
    <Snackbar style="z-index: 1000; width: 100%; position: fixed" />

    <v-main class="Loby" v-if="navBar" style="padding: 0px !important">
      <div class="container-wrap">
        <div style="border-radius: 15px" class="wrap-client">
          <!-- <HeaderPerfile />
         -->
          <!-- v-if="!isPhone"˝ -->
          <v-navigation-drawer permanent v-if="!isPhone" class="isDesktopShow">
            <div class="logo-pay">
              <img src="@/assets/lcolor.svg" class="my-2" width="120" />
            </div>

            <v-divider></v-divider>

            <v-list dense nav v-if="UserApp">
              <v-list-item
                color="primary"
                v-for="item in menu"
                :key="item.title"
                link
                :to="item.link"
              >
                <v-list-item-icon>
                  <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>{{ item.text }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <template v-slot:append>
              <div class="pa-2" style="transform: translateY(-100%)">
                <v-btn
                  block
                  dark
                  color="#202E4F"
                  @click="logOut"
                  style="border-radius: 10px"
                >
                  Salir
                </v-btn>
              </div>
            </template>
          </v-navigation-drawer>

          <!-- mobile-desing -->
          <v-app-bar
            v-if="UserApp && picture"
            app
            class="isPhoneShow"
            color="#fff"
          >
            <div class="flex-nav-mobile">
              <!-- <v-btn
                class="elevation-box"
                fab
                small
                color="#f2f2f2"
                outlined
                v-if="isPhone"
                :class="{ 'mr-2': true, 'none-back': !isBack }"
                @click="$router.go(-1)"
              >
                <v-icon small color="#000"> fa-solid fa-chevron-left </v-icon>
              </v-btn> -->
            
              <img src="@/assets/lcolor.svg" height="40" />

              <v-menu v-if="UserApp">
                <template v-slot:activator="{ on, attrs }">
                  <!-- <v-btn
                 
                    color="#282134"
                    height="52"
                    class="pr-2 pl-2"
                    style="border-radius: 10px"
                  >

                  </v-btn> -->
                  <v-avatar size="40" v-bind="attrs" v-on="on">
                    <v-img :src="picture"> </v-img>
                  </v-avatar>
                </template>
                <v-card width="200">
                  <v-card-text>
                    <v-list-item>
                      <v-avatar size="30">
                        <v-img :src="picture"> </v-img>
                      </v-avatar>
                      <span class="ml-2 text-capitalize text-caption">
                        <b> {{ UserApp.name }} {{ UserApp.surname }}</b>
                      </span>
                    </v-list-item>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn
                      block
                      dark
                      color="#202E4F"
                      @click="logOut"
                      style="border-radius: 10px"
                    >
                      Salir
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-menu>
            </div>
          </v-app-bar>

          <v-bottom-navigation
            color="primary"
            app
            center
            class="safeBar"
            shift
            v-if="isPhone && UserApp"
          >
            <v-btn
              v-for="item in menu"
              :key="item.title"
              style="border-radius: 0px 0px 10px 10px"
              link
              :to="item.link"
            >
              <span class="text-caption px-1">{{ item.text }}</span>
              <v-icon>{{ item.icon }}</v-icon>
            </v-btn>
          </v-bottom-navigation>
          <!-- mobile-desing -->
          <div class="wra-main-client" style="overflow: scroll">
            <div class="nav px-1" v-if="!isPhone">
              <v-spacer></v-spacer>
              <div class="wrap-user-text" v-if="UserApp">
                <span>{{ UserApp.name }} {{ UserApp.surname }}</span>
                <img
                  :src="picture"
                  width="40px"
                  height="40px"
                  style="border-radius: 10px; object-fit: cover"
                />
              </div>
            </div>
            <!-- <transition  name="slide-fade">  -->

            <router-view style="overflow: scroll"></router-view>

            <!-- </transition> -->
          </div>
        </div>
      </div>
    </v-main>
    <v-main class="main" v-if="!navBar">
      <router-view></router-view>
    </v-main>
    <v-dialog
      persistent
      v-model="dialogNewUser"
      v-if="dialogNewUser"
      max-width="400"
    >
      <v-card>
        <v-card-title> Completa tu perfil </v-card-title>

        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-text-field
              style="border-radius: 10px"
              v-model="newUser.name"
              required
              label="Nombre"
              :rules="textRule"
              @keydown="isString($event)"
              filled
              rounded
            ></v-text-field>
            <v-text-field
              style="border-radius: 10px"
              v-model="newUser.surname"
              required
              :rules="textRule"
              @keydown="isString($event)"
              label="Apellidos"
              filled
              rounded
            ></v-text-field>
            <v-text-field
              style="border-radius: 10px"
              v-model="newUser.email"
              required
              :rules="emailRules"
              label="Email"
              filled
              rounded
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions class="pb-4">
          <v-btn color="primary" block height="50px" @click="updateProfile">
            continuar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="openLogin"
      v-if="openLogin"
      max-width="600"
      persistent
      style="border-radius: 10px"
    >
      <Login @closed="openLogin = false" />
    </v-dialog>
  </v-app>
</template>

<script>
import { db, fb } from "@/firebase";

import { mapActions, mapState } from "vuex";
import TransitionPage from "./components/TransitionPage";
import Snackbar from "@/components/snackbar";
import Logo from "@/components/logo";
import Login from "@/components/Login.vue";
import HeaderPerfile from "@/components/headerPerfile.vue";

export default {
  name: "App",
  components: {
    HeaderPerfile,
    Snackbar,
    Logo,
    Login,
    TransitionPage,
  },
  data: () => ({
    openLogin: false,
    valid: true,
    newUser: {
      name: null,
      surname: null,
      email: null,
      completedProfile: true,
      picture:
        "https://firebasestorage.googleapis.com/v0/b/el-ocho.appspot.com/o/user.svg?alt=media&token=71ed355e-2927-4911-adf0-50bc131dd8c7",
    },
    textRule: [(v) => !!v || "Requerido"],
    emailRules: [
      (v) => !!v || "Email es requerido",
      (v) => /.+@.+\..+/.test(v) || "Ingrese un email valido",
    ],
    UserApp: null,
    mini: false,
    drawer: true,
    appFrame: null,
    navBar: null,
    pathBack: "/",
    isBack: false,
    cards: [],
    dialogNewUser: false,
    loading: false,
    selectedItem: 0,
    selectedBusiness_l: {},
    defaulImage: require("@/assets/templateImage.svg"),
    menu: [
      {
        text: "Inicio",
        icon: "fa-regular fa-house",
        link: "/home",
      },
      {
        text: "Transacciones",
        icon: "fa-regular fa-sack-dollar",
        link: "/transactions",
      },

      {
        text: "Suscripciones",
        icon: "fa-solid fa-bell",
        link: "/subscriptions",
      },
      {
        text: "Mis tarjetas",
        icon: "fa-regular fa-credit-card",
        link: "/Cards",
      },
    ],
  }),
  computed: {
    ...mapState(["role", "selectedBusiness", "search", "user"]),
    isPhone() {
      const is = screen.width < 500 ? true : false;
      return is;
    },
    picture() {
      if (
        this.UserApp &&
        this.UserApp.picture == "assets/imgs/avatar-default.svg"
      ) {
        return require("@/assets/user.svg");
      } else {
        return this.UserApp.picture || null;
      }
    },
  },
  methods: {
    ...mapActions([
      "titlepage",
      "setUser",
      "Alert_",
      "setUserValidity_",
      "addBusiness",
      "setCards",
    ]),
    updateProfile() {
      this.$refs.form.validate();
      // //console.debug(this.user[`.key`]);
      if (this.$refs.form.validate()) {
        db.collection("users")
          .doc(this.user.id)
          .update(this.newUser)
          .then(async () => {
            await db
              .collection("users")
              .doc(this.user.id)
              .collection("addresses")
              .add({
                name: "Dirección",
                neighborhood: "",
                reference: "",
                type: "office",
                geo: {
                  geohash: "d4d00nw3b",
                  geopoint: new fb.firestore.GeoPoint(14.0985425, -87.1788993),
                },
                zone: "Zona Centro",
                city: "OBTiV1PEJckU8WRPXyvz",
                country: "8HxIs06o3vDI2woWpqw3",
                deleted: false,
                default: true,
                isDemo: true,
              })
              .then((resp) => {
              
              });

            await this.$binding(
              "dataUser",
              db.collection("users").doc(this.user[`.key`])
            ).then((user) => {
              // //console.debug(user);
              this.setUser(user);
            });
            this.dialogNewUser = false;
          });
      }
    },
    isString(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        (charCode != 32 && charCode < 65 && charCode >= 48) ||
        (charCode > 90 && charCode < 97) ||
        charCode > 122
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    logOut() {
      if (this.user) {
        fb.auth()
          .signOut()
          .then(() => {
            this.$router.push({
              path: "/",
            });
            this.setUser(null);
            this.setCards(null);
          });
      }
    },
    getBussines(id) {
      db.collection("OPLBusinesses")
        .doc(id)
        .onSnapshot((response) => {
          let business = response.data();
          business[".key"] = response.id;
          this.selectedBusiness_l = business;
          this.addBusiness(business);
        });
    },
    // updateProfile() {
    //   this.$refs.form.validate();
    //   // //console.debug(this.user[`.key`]);
    //   if (this.$refs.form.validate()) {
    //     db.collection("users")
    //       .doc(this.user[`.key`])
    //       .update(this.newUser)
    //       .then(async () => {
    //         await this.$binding(
    //           "dataUser",
    //           db.collection("users").doc(this.user[`.key`])
    //         ).then((user) => {
    //           // //console.debug(user);
    //           this._user(user);
    //         });
    //         this.dialogNewUser = false;
    //       });
    //   }
    // },
    uploadImg(typeimg) {
      const image = this.File;
      fb.app()
        .storage(`gs://${process.env.VUE_APP_PROJECT_ID}-opl-businesses`)
        .ref()
        .child(`${this.idBussines}/${typeimg}.${image.type.split("/")[1]}`)
        .put(image)
        .then(() => {
          this.Alert_({
            text: "La imagen fue Actualizada",
            timeout: 2000,
            btn_closed: true,
            icon: false,
            iconType: "mdi-check",
            type: "success",
          });
          this.updateimageSuccess();
        })
        .catch((error) => {
          // //console.debug(error);
          this.Alert_({
            text: "Ocurrió un error al subir la imágen",
            timeout: 2000,
            btn_closed: true,
            icon: false,
            iconType: "mdi-check",
            type: "error",
          });
        });
    },
  },
  mounted() {
    // //console.log(process.env.NODE_ENV);
    fb.auth().onAuthStateChanged(async (authUser) => {
      let router = this.$router.currentRoute;
      // //console.debug(authUser);
      if (authUser) {
        var currentUser = await fb.auth().currentUser.getIdTokenResult();
        // //console.debug(currentUser);
        // //console.debug(currentUser.claims.userId);
        await db
          .collection("users")
          .doc(currentUser.claims.userId)
          .onSnapshot((user) => {
            user = user.data();
            // //console.debug(user);
            user.id = currentUser.claims.userId;
            user.type = "client";
            this.setUser(user);
            this.UserApp = user;
            if (!user.completedProfile) {
              this.dialogNewUser = true;
            }
            this.$binding(
              "cards",
              db
                .collection("users")
                .doc(user.id)
                .collection("cards")
                .where("active", "==", true)
                .where("deleted", "==", false)
            );
            if (!user.security.active) {
              this.Alert_({
                text: "Este perfíl de usuario no tiene permitido ingresar a la plataforma",
                timeout: 2000,
                btn_closed: true,
                icon: false,
                iconType: "mdi-check",
                type: "error",
              });
              this.logOut();
            } else {
              if (this.$router.currentRoute.name == "login") {
                this.$router.push({ path: "/home" });
              } else if (
                this.$router.currentRoute.meta.allowedRoles &&
                !this.$router.currentRoute.meta.allowedRoles.includes(user.type)
              ) {
                this.$router.push({ path: "/home" });
              }
            }
          });
      } else {
        this.setUser(null);

        var rutes = ["home", "transactions", "subscription", "card"];
        if (rutes.includes(this.$route.name) && !this.user) {
          this.$router.push("/");
        }
        // this.openLogin = true;
      }
    });
  },

  watch: {
    $route(e) {
      this.appFrame = e.meta.appFrame;
      this.navBar = e.meta.navBar;
      this.titlepage(e.meta.titlepage);

      var path = {
        home: false,
        transactions: true,
        subscription: true,
        card: true,
      };

      this.isBack = path[e.name] ?? false;
      var rutes = ["home", "transactions", "subscription", "card"];
      if (rutes.includes(this.$route.name) && !this.user) {
        this.$router.push("/");
      }
    },

    cards(e) {
      this.setCards(e);
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/scss/brands.scss";
@import "~@/assets/scss/fontawesome.scss";
@import "~@/assets/scss/light.scss";
@import "~@/assets/scss/regular.scss";
@import "~@/assets/scss/solid.scss";
@import "@/_responsive.scss";
@import "@/main.scss";
.app-vue {
  display: flex;
  justify-content: center;
  align-items: center;
}
.main {
  overflow-x: hidden;
  @include responsive(mobile) {
    padding-top: 0px;
  }
}
@font-face {
  font-family: "nexa";
  src: url("~@/assets/fonts/nexa_regular-webfont.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "nexa";
  src: url("~@/assets/fonts/nexa_light-webfont.woff2") format("woff2");
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: "nexa";
  src: url("~@/assets/fonts/nexa_bold-webfont.woff2") format("woff2");
  font-weight: bold;
  font-style: normal;
}
.icon-logo {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.v-snack__content {
  text-align: center !important;
}
.border-r {
  border-radius: 15px !important;
  overflow: hidden !important;
}
.actions-closed {
  height: 60px;
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.v-application--is-ltr .v-list-item__action:first-child,
.v-application--is-ltr .v-list-item__icon:first-child {
  margin-right: 6px !important;
}

.v-icon.v-icon {
  font-size: 16px !important;
}

.logo-l {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
  transform: translateX(-15px);
}

/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 3s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(10px);
  opacity: 0;
}

.control-input {
  width: 100%;
}

input[type="color"] {
  height: 50px !important;
  border-radius: 15px !important;
}

.selected-business {
  background-color: transparent;
}

.selected-business:hover,
.selected-business:visited {
  // background: #ff651f !important;
  border-radius: 10px !important;
}
.mobile-show {
  display: none !important;
  @include responsive(mobile) {
    display: block !important;
  }
}

.Loby {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center !important;
  align-items: center;
  background-color: #00cdbc;
  .container-wrap {
    width: 80vw;
    margin: auto;
    @include responsive(mobile) {
      width: 100vw;
      height: auto;
      margin-top: 60px;
    }
  }
  @include responsive(mobile) {
    height: auto;
    justify-content: flex-start !important;
    align-items: flex-start;
    background-color: #fff !important;
  }
  .wrap-client {
    display: flex;
    height: 80vh;
    background-color: #fff;
    overflow: hidden;
    filter: drop-shadow(0px 10px 20px #00000039);
    @include responsive(mobile) {
      filter: none;
      border-radius: 0px !important;
      width: 100%;
      height: 100% !important;
    }
    .wra-main-client {
      width: 100%;
      position: relative;
      .nav {
        width: 100% !important;
        height: 50px;
        border-bottom: #e6e6e6 solid 1px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        @include responsive(mobile) {
          padding-right: 5px;
        }
      }
    }
  }
}
.mobile-none {
  display: block;
  @include responsive(mobile) {
    display: none !important;
  }
}

.logo-pay {
  display: flex;
  width: 100%;
  align-content: center;
  justify-content: center;
}
.wrap-user-text {
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    margin-right: 10px;
    font-weight: 400;
  }
}
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.none-back {
  @include responsive(mobile) {
    display: none !important;
  }
}
.flex-nav-mobile {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
}
.none-back {
  opacity: 0;
  pointer-events: none;
}
.isPhoneShow {
  display: none !important;
  @include responsive(mobile) {
    display: block !important;
  }
}
.isDesktopShow {
  display: block !important;
  @include responsive(mobile) {
    display: none !important;
  }
}
.safeBar {
  padding-bottom: 9vh !important;

  // padding-bottom: env(safe-area-inset-bottom) !important;
  // padding-bottom: constant(safe-area-inset-bottom) !important;
}
</style>
